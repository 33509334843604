<template>
    <div class="home">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <Row justify="start">
            <Form ref="queryParams" :model="queryParams" inline>
                <i-col span="5">
                    <FormItem label="关键字">
                        <i-input
                            v-model="queryParams.maybeStr"
                            clearable
                            placeholder="患者姓名、手机号码"
                        ></i-input>
                    </FormItem>
                </i-col>
                <!--<i-col span="5">-->
                    <!--<FormItem label="用户状态">-->
                        <!--<Select v-model="queryParams.deleteStatus">-->
                            <!--<Option :value="0">正常</Option>-->
                            <!--<Option :value="1">已删除</Option>-->
                        <!--</Select>-->
                    <!--</FormItem>-->
                <!--</i-col>-->
                <i-col span="5">
                    <FormItem label="学员类型">
                        <Select v-model="queryParams.levelMember">
                            <Option :value="0">普通学员</Option>
                            <Option :value="1">VIP学员</Option>
                        </Select>
                    </FormItem>
                </i-col>
                <i-col span="5">
                    <FormItem label="升级方式">
                        <Select v-model="queryParams.upgradeType">
                            <Option :value="0">购买升级</Option>
                            <Option :value="1">手动升级</Option>
                        </Select>
                    </FormItem>
                </i-col>
                <i-col span="7">
                    <FormItem label="创建时间">
                        <Row>
                            <i-col span="11">
                                <FormItem prop="date">
                                    <DatePicker
                                        type="date"
                                        placeholder="开始时间"
                                        v-model="queryParams.startDate"
                                        @on-change="changStartTime"
                                    ></DatePicker>
                                </FormItem>
                            </i-col>
                            <i-col span="2" style="text-align: center">-</i-col>
                            <i-col span="11">
                                <FormItem prop="time">
                                    <DatePicker
                                        type="date"
                                        placeholder="结束时间"
                                        v-model="queryParams.endDate"
                                        @on-change="changEndTime"
                                    ></DatePicker>
                                </FormItem>
                            </i-col>
                        </Row>
                    </FormItem>
                </i-col>
            </Form>
        </Row>
        <Row>
            <Form ref="queryParams" :model="queryParams" inline>

                <i-col span="8">
                    <FormItem>
                        <Button
                            type="primary"
                            icon="ios-search"
                            @click="refreshTable"
                        >查询
                        </Button
                        >
                        <Button
                            type="primary"
                            icon="md-refresh"
                            ghost
                            style="margin-left: 20px;background: rgba(232, 240, 255, 1)"
                            @click="handleReset('queryParams')"
                        >重置
                        </Button
                        >
                    </FormItem>
                </i-col>
            </Form>
        </Row>
        <Row :gutter="16">
            <i-col span="24">
                <Table
                    border
                    stripe
                    :columns="tableColumns"
                    :data="tableData"
                    :loading="tableLoading"
                ></Table>
            </i-col>
        </Row>
        <br/>
        <Row :gutter="16" type="flex" justify="end">
            <i-col span="15">
                <Page
                    :total="queryParams.totalRecord"
                    :current="queryParams.pageNumber + 1"
                    :page-size="queryParams.pageSize"
                    @on-change="pIndexChange"
                    @on-page-size-change="pSizeChange"
                    show-sizer
                    show-total
                    show-elevator
                />
            </i-col>
        </Row>
    </div>
</template>
<script>
    import { getCookieUID, getCookieUNAME } from "@js/session.sg";
    import Breadcrumb from "@/components/Breadcrumb";

    export default {
        components: {
            Breadcrumb
        },
        data() {
            return {
                userId: "",
                userName: '',
                loading1: false, // 下拉框是否为搜索状态
                usersList: [], // 用户列表
                crumbData: [

                    {
                        path: "",
                        name: "用户列表"
                    }
                ],
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    maybeStr: "",
                    upgradeType: "",
                    deleteStatus: "",
                    startDate: "",
                    endDate: "",
                    levelMember: "",
                },
                tableColumns: [
                    {
                        title: "序号",
                        width: 70,
                        align: "center",
                        render: (h, params) => {
                            return h(
                                "span",
                                {},
                                params.index +
                                1 +
                                this.queryParams.pageNumber *
                                this.queryParams.pageSize
                            );
                        }
                    },
                    {
                        title: "患者姓名",
                        align: "center",
                        width: 120,
                        key: "nickName"
                    },
                    {
                        title: "手机号",
                        align: "center",
                        width: 150,
                        key: "phoneNumber"
                    },
                    {
                        title: "学员类型",
                        width: 120,
                        align: "center",
                        key: "levelMember",
                        render: (h, params) => {
                            switch (params.row.levelMember) {
                                case 0:
                                    return h("span", "普通学员");
                                case 1:
                                    return h("span", "VIP学员");
                                default:
                                    return h("strong", "--");
                            }
                        }
                    },
                    {
                        title: "脑力指数",
                        width: 120,
                        align: "center",
                        key: "mentalValue"
                    },
                    {
                        title: "创建日期",
                        key: "createTime",
                        width: 180,
                        align: "center"
                    },
                    {
                        title: "会员开始日期",
                        align: "center",
                        width: 180,
                        key: "startDate",
                        render: (h, params) => {
                            if (params.row.startDate === "" || params.row.startDate === null) {
                                return h("strong", "--");
                            } else {
                                return h("span", params.row.startDate);
                            }
                        }
                    },
                    {
                        title: "会员结束日期",
                        align: "center",
                        width: 180,
                        key: "endDate",
                        render: (h, params) => {
                            if (params.row.endDate === "" || params.row.endDate === null) {
                                return h("strong", "--");
                            } else {
                                return h("span", params.row.endDate);
                            }
                        }
                    },
                    {
                        title: "升级方式",
                        width: 120,
                        align: "center",
                        key: "deleteStatus",
                        render: (h, params) => {
                            if (params.row.levelMember === 1) {
                                switch (params.row.upgradeType) {
                                    case 0:
                                        return h("span", "购买升级");
                                    case 1:
                                        return h("span", "手动升级");
                                    default:
                                        return h("strong", "--");
                                }
                            } else {
                                return h("strong", "--");
                            }
                        }
                    },
                    {
                        title: "用户状态",
                        width: 100,
                        align: "center",
                        key: "deleteStatus",
                        render: (h, params) => {
                            switch (params.row.deleteStatus) {
                                case 0:
                                    return h("span", "正常");
                                case 1:
                                    return h("span", "已删除");
                                default:
                                    return h("strong", "--");
                            }
                        }
                    },
                    {
                        title: "操作",
                        key: "action",
                        minWidth: 200,
                        align: "center",
                        render: (h, params) => {
                            return h("div", [
                                h(
                                    "Button",
                                    {
                                        props: {
                                            type: "error",
                                            size: "small"
                                        },
                                        style: {
                                            marginRight: "5px"
                                        },
                                        on: {
                                            click: () => {
                                                this.upgrade(params.row.userId, params.row.nickName, params.row.levelMember);
                                            }
                                        }
                                    },
                                    "升级半年"
                                ),
                                h(
                                    "Button",
                                    {
                                        props: {
                                            type: "error",
                                            size: "small"
                                        },
                                        style: {
                                            marginRight: "5px"
                                        },
                                        on: {
                                            click: () => {
                                                this.upgrade(params.row.userId, params.row.nickName, params.row.levelMember, 1);
                                            }
                                        }
                                    },
                                    "升级一年"
                                )
                                // h(
                                //     "Button",
                                //     {
                                //         props: {
                                //             type: "primary",
                                //             size: "small"
                                //         },
                                //         style: {
                                //             marginRight: "5px"
                                //         },
                                //         on: {
                                //             click: () => {
                                //                 this.toDelete(
                                //                     params.row.userId,
                                //                     params.row.doctorId
                                //                 );
                                //             }
                                //         }
                                //     },
                                //     "删除"
                                // ),
                            ]);
                        }
                    }
                ],
                tableData: [],
                tableLoading: false,
                editModal: false,
            };
        },
        mounted() {
            this.initTable();
        },
        methods: {
            remoteMethod1(query) {
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                if (query !== "") {
                    this.queryParams.keyWord = query;
                    this.timer = setTimeout(() => {
                        // this.getHospitalList();
                    }, 500);
                }
            },
            // 开始时间选中
            changStartTime(value) {
                this.queryParams.startDate = value;
            },
            // 结束时间选择
            changEndTime(value) {
                this.queryParams.endDate = value;
            },
            // 获取用户列表
            initTable: function () {
                this.tableLoading = true;
                this.$http
                    .gets("backstage/user/api/getUserApiListPage", this.queryParams)
                    .then(res => {
                        if (res.code === 1) {
                            // todo
                            this.tableData = res.data.list;
                            this.queryParams.totalRecord = res.data.total;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                        }
                    })
                    .catch(err => {
                        this.tableLoading = false;
                        console.log(err);
                    });
            },
            // 重置
            handleReset(name) {
                this.$refs[name].resetFields();
                this.queryParams.startDate = "";
                this.queryParams.endDate = "";
                this.queryParams.maybeStr = "";
                this.queryParams.upgradeType = "";
                this.queryParams.deleteStatus = "";
                this.queryParams.levelMember = "";
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            pIndexChange: function (page) {
                this.queryParams.pageNumber = page - 1;
                this.initTable();
            },
            pSizeChange: function (size) {
                this.queryParams.pageSize = size;
                this.initTable();
            },
            // 确定
            confirm2: function () {
                let data = {
                    userId: this.userId,
                    levelDeadline: this.levelDeadline
                };
                this.$http
                    .post(
                        "backstage/user/api/upgradeUserApi",
                        this.$http.adornData(data, false, "json"),
                        this.$http.jsonContent()
                    )
                    .then(res => {
                        console.log(res);
                        if (res.code === 1) {
                            console.log(res);
                            this.initTable();
                        } else {
                            console.log(res);
                            this.initTable();
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            },
            // 取消
            cancel: function () {
                this.editModal = false;
            },

            // 删除
            toDelete: function (patientId, doctorId) {
                this.editModal = true;
            },
            // 升级会员
            upgrade: function (patientId, nickName, levelMember, levelDeadline = 0) {
                this.userName = nickName;
                this.userId = patientId;
                this.levelDeadline = levelDeadline
                // if (levelMember === 0) {
                    this.$Modal.confirm({
                        render: h => {
                            return h("div", [
                                // 第一个参数'div'表示创建父节点div，如果要为该节点设置样式，则第二个参数应为一个object,这里不设置样式所以省略了，第三个参数[]表示创建div下的子节点
                                h(
                                    "div",
                                    {
                                        // 创建子节点div,设置样式，创建其子节点Icon和span
                                        class: { "modal-title": true },
                                        style: {
                                            display: "flex",
                                            height: "42px",
                                            alignItems: "center"
                                        }
                                    },
                                    [
                                        h("Icon", {
                                            props: {
                                                type: "ios-alert"
                                            },
                                            style: {
                                                width: "28px",
                                                height: "28px",
                                                fontSize: "28px",
                                                color: "#ed4014"
                                            }
                                        }),
                                        h("span", {
                                            style: {
                                                marginLeft: "12px",
                                                fontSize: "16px",
                                                color: "#17233d",
                                                fontWeight: 500
                                            },
                                            domProps: { innerHTML: "升级学员" }
                                        })
                                    ]
                                ),
                                h(
                                    "div",
                                    {
                                        // 创建内容区div节点，设置样式
                                        class: { "modal-content": true },
                                        style: { paddingLeft: "40px" }
                                    },
                                    [
                                        h("p", {
                                            // 创建content的子节点 p标签、label标签、RadioGroup
                                            style: { marginBottom: "15px" },
                                            domProps: {
                                                innerHTML: levelMember === 0 ? `确定把${this.userName}升级为VIP学员吗?` : `${this.userName}学员已经是VIP? 确定续签吗?`
                                            }
                                        })
                                    ]
                                )
                            ]);

                        },
                        onOk: () => {
                            this.confirm2()
                        },
                        onCancel: () => {
                        }
                    })
                // } else {
                    // this.$Message.info('该用户已是VIP学员');
                // }
            }

        }
    };
</script>
<style lang="scss" scoped>
    @import "@css/tableCenter";

    .home {
        height: 100%;
        width: 100%;
    }

    ::v-deep .ivu-select-selection{
        width: 100px;
    }
    /*修改 表单间距*/
    ::v-deep .ivu-form-item {
        display: flex;
        align-items: center;

        .ivu-form-item-label {
            text-align: left;
            padding: 0;
            margin-right: 10px;
            white-space: nowrap;
        }
    }

    ::v-deep .patientDate {
        .ivu-modal {
            width: 910px !important;
        }

        .ivu-modal-body {
            height: 500px;
            overflow: auto;
            padding: 20px 40px;

            .detailone {
                &:last-child {
                    margin-top: 24px;
                }

                .title {
                    display: flex;
                    align-items: center;

                    img {
                        width: 30px;
                        height: 22px;
                        margin-right: 10px;
                    }

                    h1 {
                        font-size: 16px;
                        font-weight: 500;
                        color: #17233d;
                    }
                }

                .detail {
                    padding: 23px 35px;
                    background: #f6f7fb;
                    border: 1px solid #d7d9de;
                    border-radius: 2px;
                    margin-top: 12px;

                    .one {
                        display: flex;
                        align-items: center;

                        h1 {
                            font-size: 14px;
                            font-weight: 400;
                            color: #515a6e;
                            margin-right: 14px;
                        }

                        p {
                            font-size: 14px;
                            font-weight: 400;
                            color: #515a6e;
                            opacity: 0.7;
                        }
                    }

                    .history {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;

                        h1 {
                            font-size: 14px;
                            font-weight: 400;
                            color: #515a6e;
                            margin-right: 14px;
                        }

                        p {
                            font-size: 14px;
                            font-weight: 400;
                            color: #515a6e;
                            opacity: 0.7;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
</style>
